.button {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
  border-bottom: 4px solid brown;
  color: white;
  margin: 10px;
  font-size: 25px;
}
.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: rgb(238, 238, 238);
  color: black;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  cursor: pointer;
}
.closeButton:hover {
  background-color: lightgrey;
}
.tableRow {
  cursor: pointer;
}
.tableRow:hover {
  background-color: aliceblue;
}
