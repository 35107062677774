.App {
  /* display: flex;
  flex: 1; */
  width: 100%;
  height: 100%;
  /* justify-content: center;
  align-items: center;
  flex-direction: column; */
}

h1 {
  font-size: 50px;
  background-image: linear-gradient(45deg, #00bbf9, #7d53de);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

h2 {
  font-size: 25px;
  background-image: linear-gradient(45deg, #ffbe0b, #ff006e);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  padding: 5px;
}
