.dataDisplay {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
}
.dataDisplay .value {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: orange;
  font-size: 40px;
  font-weight: 700;
  color: white;
  border-radius: 50%;
}
.dataDisplay .subText {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 700;
}
