.root {
  position: relative;
  padding: 15px 80px 60px 80px !important;
}

.exercise {
  margin-top: 20px;
  padding: 15px 0 15px 0;
  background-color: #414458;
  text-align: center;
  position: relative;
}
.exercise .title {
  line-height: 50px;
  width: 100%;
  text-align: center;
}

.exercise .title span {
  display: inline-block;
  position: relative;
  /* text-align: center; */
  /* margin: 0 60px 20px 60px; */
  font-size: 28px;
}
.exercise .title span:before,
.exercise .title span:after {
  margin: 0px 20px 0px 20px;
  content: "";
  /* position: absolute; */
  display: inline-block;
  height: 10px;
  /* border-bottom: 1px solid white; */
  border-top: 1px solid white;
  width: 50px;
  /* top: 0; */
  /* width: 600px; */
}
.exercise .description {
  color: white;
  position: relative;
  padding: 0;
  /* background-color: orange; */
  vertical-align: middle;
  margin-top: 20px;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 30px;
  /* background-color: white; */
  /* margin: 0 60px 0 60px; */
  /* font-size: 16px; */
  /* font-family: "Open Sans", sans-serif; */
  background: rgba(40, 42, 54, 0.8);
  /* background: rgba(68, 120, 188, 1); */
  padding: 20px 40px 20px 40px;
  border-radius: 10px;
  /* border: 3px solid white; */
  display: inline-block;
}
.exercise .description img {
  margin-top: 20px;
}

.subsection {
  color: white;
  text-align: center;
  font-size: 20px;
  margin-top: 30px;
  /* display: inline-block; */
  margin-left: 0px;
  margin-right: 0px;
  vertical-align: middle;
  /* border: 2px solid white; */
  background: rgba(40, 42, 54, 0.8);
  border-radius: 10px;
  padding: 15px 25px 15px 25px;
  /* margin: 0px 50px 20px 50px; */
}
.tips {
  vertical-align: middle;
  text-align: left;
  margin: 20px 40px 60px 40px;
  font-size: 25px;
  list-style-type: decimal !important;
}
.materials {
  text-align: center;
}
.materials img {
  height: 90px;
  width: auto;
  /* margin: 20px 10px 0px 10px; */
  min-width: 150px;
}
.materialElement {
  display: inline-block;
  text-align: center;
  margin: 30px 10px 30px 10px;
}
