.ide {
  text-align: left;
  padding: 0px;
  margin: 10px 0px 10px 0px;
  border-radius: 2px;
  position: relative;
}

.tab {
  color: white;
  background-color: #282a36;
  display: inline-block;
  padding: 15px 20px 15px 20px;
  margin-right: 20px;
  font-size: 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
i {
  margin-left: 10px;
  color: white;
}
.errorMarker {
  position: absolute;
  background-color: red;
  opacity: 0.6;
  z-index: 2;
}
.output {
  background-color: #16171d;
  color: white;
  padding-left: 20px;
  width: 100%;
}
.outputLabel {
  padding: 10px 10px 10px 10px;
  background-color: #16171d;
  border-bottom: 1px solid #21222c;
  font-size: 16px;
  color: white;
}
.actionButton {
  margin: 0px 10px 10px 10px !important;
}
.actionButton:focus {
  outline: none;
}
