.button {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
  border-bottom: 4px solid brown;
  color: white;
  margin: 10px;
  font-size: 25px;
}
.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: rgb(238, 238, 238);
  color: black;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  cursor: pointer;
}
.closeButton:hover {
  background-color: lightgrey;
}

.leaderboard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}
.leaderboardRow {
  background-color: orange;
  display: flex;
  justify-content: space-between;
  color: white;
  font-weight: 600;
  padding: 20px 20px;
  margin: 5px;
  width: 300px;
  border-radius: 20px;
  align-items: center;
}
.leaderboardRow .name {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
